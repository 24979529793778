<script lang="ts">
import {
  defineUseManagedListBlockProps,
  UseManagedListBlockEmits,
} from 'ah-common-lib/src/listing/useManagedListBlockInterfaces';
import { PropType } from 'vue';

export const ahPanelListBlockProps = {
  ...defineUseManagedListBlockProps(),

  showExport: { type: [Boolean, String], default: false },

  showTableFooterTotals: { type: [Boolean, String], default: true },

  showPagination: { type: [Boolean, String], default: true },

  showColConfigurator: { type: [Boolean, String], default: false },

  showSizeSelector: { type: [Boolean, String], default: true },

  withHeaderFilters: { type: [Boolean, String], default: true },

  /**
   * Whether to manage item selection internally
   * (any value other than false is considered truthy)
   *
   * This will:
   *  - Add a column 'selectCheckbox' to the table, if one does not already exist
   *  - Display a selection management UI, if at least one item is selected
   *  - Warn of selection clearing if filter are updated
   */
  withManagedSelection: { type: [Boolean, String], default: false },

  /**
   * Whether to select item on line click
   * Only relevant if withManagedSelection is truthy
   *
   * Set this to false if using a custom checkbox solution/want to allow selecting only via the checkbox
   */
  selectOnLineClick: { type: [Boolean, String], default: true },

  cardless: { type: [Boolean, String], default: false },

  paddingless: { type: [Boolean, String], default: false },

  settingsInOwnRow: { type: [Boolean, String], default: false },

  allowExportWithDetails: { type: [Boolean, String], default: false },

  downloadFormatOptions: {
    type: Array as PropType<{ value: string; text: string }[]>,
    default: () => [
      { value: 'PDF', text: 'PDF' },
      { value: 'XLSX', text: 'Excel' },
      { value: 'CSV', text: 'CSV' },
    ],
  },

  pageOptions: {
    type: Array as PropType<{ label: string; value: number }[]>,
    default: () => [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ],
  },
};

export interface AHPanelListBlockEmits extends UseManagedListBlockEmits {}
</script>

<script lang="ts" setup>
import { cloneDeep } from 'lodash';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import PaginationControls from 'ah-common-lib/src/common/components/listing/PaginationControls.vue';
import SizeSelector from 'ah-common-lib/src/common/components/SizeSelector.vue';
import TableColumnEditorModal from 'ah-common-lib/src/common/components/listing/TableColumnEditorModal.vue';
import { useSettingsStore } from '@/app/store/settingsModule';
import { convertColumnsToDefinition } from 'ah-common-lib/src/helpers/table';
import { ListingTriggers } from 'ah-common-lib/src/common/components/listing/Listing.vue';
import { BModal } from 'bootstrap-vue';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { computed, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useManagedListBlock } from 'ah-common-lib/src/listing/useManagedListBlock';

interface SelectAllProcess {
  state: 'confirm' | 'processing' | 'error';
  selectedItems: string[];
  total: number;
}

/**
 * Base List block component
 *
 * Meant for usage alongside a <Listing> and a <fooFilter> components, controlling filter flow between both
 * All data is synchronized via slot scopes to Listing and Filter components
 *
 * This component does NOT save filter/pagination data. Any query-string-based data saving should happen on the child components
 * (or, as is common, on the Filter components, which MAY ALSO save pagination data)
 */

const props = defineProps(ahPanelListBlockProps);

const emit = defineEmits<AHPanelListBlockEmits>();

const requestManager = useRequestManager().manager;

const settingsStore = useSettingsStore();

const selectAllModal = ref<BModal>();

const listingTriggers = ref<ListingTriggers<any>>();

const managedListBlock = useManagedListBlock({
  reqManager: requestManager,
  props,
  emit,
});

const selectAllProcess = ref<SelectAllProcess>({
  state: 'confirm',
  total: 0,
  selectedItems: [],
});

const filterSlotScope = managedListBlock.filterSlotScope;

/**
 * Slot scope for the list slot. All necessary hooks are exposed, grouped into `listeners` and `props` for a less verbose integration
 *
 * Listeners for filter and sortAndPageParams for listsings are SYNC by default (as opposed to the filter listeners, which update on a debounce)
 */
const listSlotScope = computed(() => {
  return {
    ...managedListBlock.listSlotScope.value,
    listeners: {
      ...managedListBlock.listSlotScope.value.listeners,
      ['update:triggers']: onListTriggersUpdate,
      ['row-clicked']: onRowClicked,
      ['row-toggle-all']: onRowToggleAll,
    },
    props: {
      ...managedListBlock.listSlotScope.value.props,
      config: config.value,
    },
    onListTriggersUpdate: onListTriggersUpdate,
    downloadData: downloadData,
    loadData: loadData,
  };
});

function triggerSelectAllModal() {
  selectAllProcess.value.state = 'confirm';
  selectAllModal.value?.show();
}

function cancelSelectAllItems() {
  requestManager.cancel('selectAll');
  selectAllModal.value?.hide();
}

function selectAllItems() {
  const pageSize = 50;
  selectAllProcess.value.total = 0;
  selectAllProcess.value.selectedItems = [];
  const recursiveDataRequest = (pageNumber: number, selection: string[]): Observable<string[]> => {
    return listingTriggers.value!.loadDataRequest({ ...managedListBlock.refs.filter.value, pageNumber, pageSize }).pipe(
      map((result) => {
        const currSelection = [
          ...selection,
          ...result.list.map((i) => i[managedListBlock.refs.itemPrimaryKey.value!] as string),
        ];
        selectAllProcess.value.total = result.total;
        selectAllProcess.value.selectedItems = [...currSelection];
        return {
          total: result.total,
          selection: currSelection,
        };
      }),
      mergeMap((result) =>
        pageSize * (pageNumber + 1) < result.total
          ? recursiveDataRequest(pageNumber + 1, result.selection)
          : of(result.selection)
      )
    );
  };

  selectAllProcess.value.state = 'processing';
  requestManager.cancelAndNew('selectAll', recursiveDataRequest(0, [])).subscribe(
    (selectedItems) => {
      managedListBlock.setSelectedItems(selectedItems);
      selectAllModal.value?.hide();
    },
    () => {
      selectAllProcess.value.state = 'error';
    }
  );
}

function onListTriggersUpdate(triggers: ListingTriggers<any>) {
  listingTriggers.value = triggers;
}

function onRowClicked(row: any) {
  if (shouldManageSelection.value && props.selectOnLineClick) {
    managedListBlock.toggleItemSelection(row);
  }
}

function onRowToggleAll() {
  if (shouldManageSelection.value) {
    const allSelected = !data.value?.list.find((i) => !managedListBlock.isItemSelected(i));
    data.value?.list.forEach((i) => managedListBlock.toggleItemSelection(i, !allSelected));
  }
}

function loadData() {
  listingTriggers.value?.loadData();
}

function downloadData(type: string, options: { [key: string]: any }) {
  listingTriggers.value?.downloadData(type, options);
}

function updateTableConfig(columns: string[]) {
  if (props.listConfig?.editConfig?.storeKey) {
    settingsStore.setSavedTableColumns({
      key: props.listConfig.editConfig!.storeKey,
      value: columns,
    });
  }
}

const settingsColWidth = computed(() => {
  let out = 0;
  if (shouldShowColConfigurator.value) {
    out += 2;
  }
  if (shouldShowSizeSelector.value) {
    out += 1;
  }
  return out;
});

const selectAllCompletionPercentageString = computed(() => {
  if (selectAllProcess.value.selectedItems.length === 0) {
    return '0%';
  }
  if (selectAllProcess.value.total === 0) {
    return '100%';
  }
  return `${((selectAllProcess.value.selectedItems.length / selectAllProcess.value.total) * 100).toFixed(2)}%`;
});

const dataDownloadState = computed(() => managedListBlock.refs.dataDownloadState.value);

const shouldShowColConfigurator = computed(() => props.showColConfigurator !== false);

const shouldShowSizeSelector = computed(() => props.showSizeSelector !== false);

const areSettingsInOwnRow = computed(() => props.settingsInOwnRow !== false);

const shouldShowSettings = computed(() => shouldShowSizeSelector.value || shouldShowColConfigurator.value);

const shouldManageSelection = computed(() => props.withManagedSelection !== false);

const shouldShowPagination = computed(() => props.showPagination !== false);

const selectedItems = computed(() => managedListBlock.refs.selectedItems.value || []);

const data = computed(() => managedListBlock.refs.data.value);

const sortAndPageParams = computed(() => managedListBlock.refs.sortAndPageParams);

const shouldShowHeader = computed(() => props.withHeaderFilters !== false);

const shouldShowFooter = computed(() => (managedListBlock.refs.data.value?.list.length || 0) > 0);

const config = computed(() => {
  if ((tableColumns.value, props.listConfig)) {
    const tableFields = cloneDeep(convertColumnsToDefinition(tableColumns.value, props.listConfig));

    if (shouldManageSelection.value && !tableFields.find((f) => f.key === 'selectCheckbox')) {
      tableFields.unshift({
        key: 'selectCheckbox',
        header: '',
      });
    }
    return {
      tableFields,
    };
  }
  return undefined;
});

const tableColumns = computed<string[]>(() => {
  return managedListBlock.refs.listConfig?.value?.editConfig
    ? settingsStore.getSavedTableColumns(managedListBlock.refs.listConfig.value.editConfig.storeKey)
    : [];
});

defineExpose({
  refreshData: () => loadData(),
  setFilter: (filter: any, useFilterSetHook?: boolean) => managedListBlock.setFilter(filter, useFilterSetHook),
});
</script>

<template>
  <Component :is="cardless !== false ? 'div' : 'BCard'" no-body>
    <!-- slots from parent -->
    <BCardHeader :class="{ 'py-4': !paddingless }" class="filter" v-if="shouldShowHeader">
      <VRow>
        <VCol :cols="areSettingsInOwnRow ? 12 : 12 - settingsColWidth">
          <slot name="filters" v-bind="filterSlotScope" />
        </VCol>
        <VCol :cols="settingsColWidth" class="ml-auto" v-if="shouldShowSettings">
          <VRow>
            <VCol
              class="mock-label-padding text-right"
              :cols="shouldShowSizeSelector ? 8 : 12"
              v-if="shouldShowColConfigurator"
            >
              <TableColumnEditorModal
                :config="listConfig"
                :columns="tableColumns"
                @update:columns="updateTableConfig"
                class="btn-primary"
              />
            </VCol>
            <VCol :cols="shouldShowColConfigurator ? 4 : 12" v-if="shouldShowSizeSelector">
              <SizeSelector
                :selected-value="sortAndPageParams.pageSize || 10"
                @update:selected-value="managedListBlock.setPageSize"
                :customRowsOptions="pageOptions"
              />
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </BCardHeader>
    <BCardBody>
      <div class="item-selection clearfix" v-if="data && shouldManageSelection && selectedItems.length">
        <span class="description"> {{ selectedItems.length }} of {{ data.total }} selected </span>
        <span class="actions">
          <a class="link deselect" @click="managedListBlock.setSelectedItems([])">Deselect all</a>
          <a class="link select-all" @click="triggerSelectAllModal">Select all {{ data.total }} results</a>
        </span>
      </div>
      <BModal modal-class="select-all-modal" ref="selectAllModal" title="Select All" hide-footer centered>
        <template>
          <template v-if="selectAllProcess.state === 'confirm'">
            <p class="text-center mb-5">
              Select all <span v-if="data">{{ data.total }}</span> results?
            </p>
            <p class="text-center">
              <VButton class="mr-3" @click="cancelSelectAllItems">Cancel</VButton>
              <VButton class="btn-primary" @click="selectAllItems"> Confirm </VButton>
            </p>
          </template>
          <template v-else-if="selectAllProcess.state === 'processing'">
            <p class="text-center mb-5">
              Selecting all <span v-if="data">{{ data.total }}</span> results...
            </p>
            <div class="progress mb-4 mt-2">
              <div
                class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                role="progressbar"
                :style="`width: ${selectAllCompletionPercentageString}`"
              ></div>
            </div>
            <p class="text-center">
              <VButton class="mr-3" @click="cancelSelectAllItems">Cancel</VButton>
            </p>
          </template>
          <template v-else-if="selectAllProcess.state === 'error'">
            <p class="text-center mb-5">An error occurred while attempting to select all items. Please try again</p>
            <p class="text-center">
              <VButton class="mr-3" @click="cancelSelectAllItems">Close</VButton>
            </p>
          </template>
        </template>
      </BModal>
      <slot name="list" v-bind="listSlotScope" />
    </BCardBody>
    <slot name="footer" v-if="shouldShowFooter">
      <BCardFooter class="footer">
        <div class="table-actions">
          <ListDownloadButton
            v-if="showExport !== false"
            :loading="dataDownloadState === 'pending'"
            class="download-button"
            @download="downloadData"
            :downloadOptions="downloadFormatOptions"
            :allowExportWithDetails="allowExportWithDetails"
          >
            <template slot="list-download-modal-title">
              <slot name="list-download-modal-title"></slot>
            </template>
            <template slot="list-download-modal-description">
              <slot name="list-download-modal-description"></slot>
            </template>
          </ListDownloadButton>
          <PaginationControls
            v-if="shouldShowPagination"
            :data="data"
            :showTotals="showTableFooterTotals"
            @page-changed="managedListBlock.setPage($event)"
            class="pagination-wrapper"
          />
        </div>
        <slot name="footer" />
      </BCardFooter>
    </slot>
  </Component>
</template>

<style lang="scss" scoped>
.footer {
  background-color: $gray-100;

  ::v-deep {
    .page-item.active > .page-link {
      background-color: $primary;
      border-color: $primary;
      color: white;
    }
  }
}

.card-body {
  padding: 0;

  ::v-deep {
    .table,
    .p-datatable {
      margin-bottom: 0;

      th {
        border-top: none;
      }
    }
  }
}

.item-selection {
  background: rgb(36 70 166 / 15%);
  margin: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  color: rgb(36 70 166);

  .description {
    float: left;
  }

  .actions {
    float: right;

    .link {
      text-decoration: none;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    .deselect {
      color: #d65644;
      margin-right: 1em;
    }
  }
}

.table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  .download-button {
    margin-right: auto;
    margin-top: 0;
  }

  ::v-deep {
    .pagination-wrapper {
      margin-left: auto;
      .pagination {
        margin-bottom: 0;
      }
    }
  }
}

.filter {
  background-color: $gray-100;
}
</style>
